import React from 'react';
import { ErrorMessage, useField } from 'formik';
import 'bootstrap/dist/css/bootstrap.min.css';
import NumericInput from 'react-numeric-input';

export const NumericField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-2">
      <label className="text-decoration-line-through" style={{fontWeight:'bold', fontSize:12 , color:'#434175'}} htmlFor={field.name}>{label}</label>
      {/* <input
        className={`form-control shadow-none ${meta.touched && meta.error && 'is-invalid'}`}
        {...field} {...props}
        autoComplete="off"
      /> */}
      <NumericInput mobile  className={`form-control shadow-none ${meta.touched && meta.error && 'is-invalid'}`}    {...props} />
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  )
}

