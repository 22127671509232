import React from 'react';
import styled from 'styled-components';
import j2wlogo_v1 from '../assets/images/j2wlogo_v1.png';

const HeaderContainer = styled.header`
  background-color: #E6E6FA;
  color: #fff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.img`
  max-width: 100px;
  margin-right: 10px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center; /* Center the text horizontally and vertically */
  justify-content: center; /* Center the text horizontally and vertically */
`;

const HeaderText = styled.h1`
  margin: 0;
  color: #000;
`;

const SubHeaderText = styled.h2`
  margin: 0;
`;

const Header1 = () => {
  return (
    <HeaderContainer>
      <Logo src={j2wlogo_v1} alt="Company Logo" />
    </HeaderContainer>
  );
};

export default Header1;
