import React from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import animation from '../assets/json/404_web.json'
import styled from "styled-components";


const Button = styled.button`
  background-color: #45AAD7;
  color: white;
  font-size: 16px;
  padding: 8px 40px;
  border-radius: 5px;
  border-color: #fff;
  box-shadow: 0in;
  margin: 8px 0px;
  cursor: pointer;
  align-self: center;
`;

const Main = styled.div`
display:flex ;
flex-direction: column;
color: red;
align-items: center;
`



const NotFound = () => (


  <Main>
    <Lottie
      width={500}
      height={500}

      options={{
        animationData: animation
      }}
    />

<Lottie
loop={true}
style={{ width: '50%', height: '50%', alignSelf: "center" }}
animationData={animation} />


    <Link to="/">
      <Button>
        HOME 
      </Button>
    </Link>

  </Main>
);

export default NotFound;