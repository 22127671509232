import React, { useState } from 'react';

import { Formik, Form } from 'formik';
import { TextField } from './TextField';
import * as Yup from 'yup';
import { constants } from '../config/constants';
// import NumericInput from 'react-numeric-input';
// import axios from 'axios';
// import Select from "react-select";

// import makeAnimated from "react-select/animated";
// import MySelect from "./MySelect";

import Lists from './MenuList';
// import { FixedSizeList as List } from "react-window";
import { NumericField } from './NumericField';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/




const options = [];
for (let i = 0; i < 10000; i = i + 1) {
  options.push({ value: i, label: `Option ${i}` });
}


export const Signup = ({ data, token, parentCallback, skillData, skillDatafunction }) => {

  const [selectedFile, setSelectedFile] = useState();
  // const [isFilePicked, setIsFilePicked] = useState(false);
  const [optionSelected, setoptionSelected] = useState();

  const profile = data
  const validate = Yup.object({
    firstName: Yup.string()
      .min(3, 'Must be 15 characters or less')
      .max(15, 'Must be 15 characters or less')
      .required('Required'),
    lastName: Yup.string()
      .max(20, 'Must be 20 characters or less')
      .required('Required'),
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required'),
    resume: Yup.string()
      .required('Resume is required'),
    phone: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Phone number is required')
      .max(10, 'Must be 10 characters')
      .min(10, 'Must be 10 characters'),
    resume: Yup.mixed()
      .required("Resume is required"),
    skill: Yup.mixed()
      .required("Skill is required"),
    experience: Yup.mixed()
      .required("Skill is required")
  })







  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: profile.first_name,
        lastName: profile.last_name,
        email: profile.email,
        phone: profile.contact_phone,
        resume: null,
        skill: '',
        experience: '',
      }}
      validationSchema={validate}
      onSubmit={values => {
        var skill = JSON.stringify(values.skill)
        parentCallback(0);
        const formData = new FormData();

        formData.append('file', selectedFile);
        formData.append('full_name', values.firstName + ' ' + values.lastName);
        formData.append('email', values.email);
        formData.append('phone', values.phone);
        formData.append('skill', skill);
        formData.append('experience', values.experience);

        fetch(
          `${constants.BaseURL}:3600/api/register`,
          {
            method: 'POST',
            body: formData,
            headers: {
              'Authorization': `Bearer ${token}`,
            },

          },

        )
          .then((response) => response.json())
          .then((result) => {
            console.log('Success:', result);
            if (result.error === 'invalid token') {
              parentCallback(2)
            }
            else {
              parentCallback(1);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
            parentCallback(2);
          });



      }}
    >
      {({ errors, touched, setFieldValue }) => (
        <div>
          <h2 className="my-4 font-weight-bold .display-2">Update Your Profile</h2>
          <Form>
            <TextField label="First Name" name="firstName" type="text" />
            <TextField label="last Name" name="lastName" type="text" />
            <TextField label="Email" name="email" type="email" />
            <TextField label="phone" name="phone" type="text" />
            <TextField id="file" label="Resume" name="resume" type="file" onChange={(event) => {
              setSelectedFile(event.target.files[0]);
              // setIsFilePicked(true);
              setFieldValue("resume", event.target.files[0]);


            }} value={undefined} />



            <Lists onChange={(e) => {
              // let {name, value} = event.target.value;
              setFieldValue("skill", e);

              console.log("SKILL" + e)
              // setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
              setFieldValue("skill", Array.isArray(e) ? e.map(x => x.value) : [])
              console.log(Array.isArray(e) ? e.map(x => x.value) : [])
              setoptionSelected(Array.isArray(e) ? e.map(x => x.value) : [])
            }} options={skillData} />

            <NumericField label="Experience" name="experience" onChange={(value) => {
            
              setFieldValue("experience",value);


            }} />

            <button className="btn btn-dark mt-3" type="submit">Submit</button>
            <button className="btn btn-danger mt-3 ml-3" type="reset">Reset</button>
          </Form>

        </div>
      )}
    </Formik>


  )
}
