import React, { Component } from 'react'
import axios from 'axios';
import '../screens/screenCSS.css';
import { Signup } from '../components/Signup';

import Lottie from 'lottie-react';
import animation from '../assets/json/upload_json.json'
import SuccessfulPage from '../components/SuccessfulPage';
import Footer from '../components/Footer';
import { ThemeProvider } from 'styled-components'
import { Container } from '../components/styles/Container.styled';
import Header from '../components/Header';
import { constants } from '../config/constants';


import ErrorPage from '../components/ErrorPage';
import LoadingPage from '../components/LoadingPage';
import GlobalStyles from '../components/styles/Global';

const theme = {
  colors: {
    header: '#E6E6FA',
    body: '#fff',
    footer: '#003333',
  },
  mobile: '768px',
}



export class candidateUpdateScreen extends Component {


  constructor(props) {
    super(props);
    this.state = {
      load: true,
      error1: false,
      loading: true,
      profileData: [],
      params: this.props.match.params,
      profileFile: null,
      skillData: [],
    }

  }




  componentDidMount() {


    axios({
      method: 'GET',
      url: `${constants.BaseURL}:3600/api/profile`,
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + this.state.params.id
      },
    })
      .then(response => {
        this.setState({ profileData: response.data.data })
        this.setState({ load: false, loading: false, error1: false });

      })
      .catch(err => {
        console.log(err);
        // alert(JSON.stringify(err.response.status));
        // if (err.response.status === 401) {
        this.setState({ load: true, loading: false, error1: true });
        // }

      });


    axios({
      method: 'POST',
      url: `${constants.BaseURL}:3600/api/skill`,
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + this.state.params.id
      },
    })
      .then(response => {
        this.setState({ skillData: response.data.data })
        this.setState({ load: false, loading: false, error1: false });

      })
      .catch(err => {
        console.log(err);
        // alert(JSON.stringify(err.response.status));
        // if (err.response.status === 401) {
        this.setState({ load: true, loading: false, error1: true });
        // }

      });

  }


  handleCallback = (load) => {
    if (load === 0) {
      this.setState({ load: true, loading: true, error1: false });
    }
    else if (load === 1) {

      this.setState({ load: true, loading: false, error1: false });
    }
    else if (load === 2) {

      this.setState({ load: true, loading: false, error1: true });
    }
    else {
      this.setState({ load: true, loading: false, error1: true });
    }

  }


  skillDatafunction = (selected) => {
    alert(selected)
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Header />
        {this.state.load ? this.state.loading ? <LoadingPage /> : this.state.error1 ? <ErrorPage /> : <SuccessfulPage /> :
          <Container>
            {/* <img className="img-fluid w-1000" style={{ width: '15%', height: '15%' }} src={j2wlogo} alt="" /> */}
            <div className="row">
              <div className="col-md-5">
                <Signup data={this.state.profileData}
                  skillData={this.state.skillData}
                  load={this.state.load} token={this.state.params.id}
                  increaseCount={(load) => this.setState({ load })}
                  parentCallback={this.handleCallback}
                  skillDatafunction={this.skillDatafunction}
                />
              </div>
              <div className="col-md-7 my-auto ml-md-n10">
           
                <Lottie
                  style={{width : '100%', height:'100%', alignSelf:"center"}}
                  animationData={animation} />
              </div>
            </div>

          </Container>

        }
        <Footer />
      </ThemeProvider>
    )
  }
}

export default candidateUpdateScreen