import React from 'react';
//import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import animation from '../assets/json/successful.json';
import styled from "styled-components";
import useWindowDimensions from '../utils/useWindowDimensions';


// const Button = styled.button`
//   background-color: #45AAD7;
//   color: white;
//   font-size: 16px;
//   padding: 8px 40px;
//   border-radius: 5px;
//   border-color: #fff;
//   box-shadow: 0in;
//   margin: 8px 0px;
//   cursor: pointer;
//   align-self: center;
 
// `;

const Main = styled.div`
display:flex ;
flex-direction: column;
color: red;
align-items: center;
margin: 10vh;
`
const Thanks = styled.p`
font-size: 22px;
margin-top: 10px;
font-weight: bold;
color: #3A71FC;
font-family: 'Garamond', 'serif', cursive;

@media (max-width: ${({ theme }) => theme.mobile}) {
    margin: 40px 0 30px;
    font-size: 18px;
  
  }

`




const SuccessfulPage = () => {

  const {  width } = useWindowDimensions();

  return (
  <Main>
    <Lottie
       width={width > 768 ? '25%' : '50%'  }
       height={width > 768 ? '25%' : '50%' }
      options={{
        animationData: animation,
      }}
    />
    <Thanks>THANKS FOR SUBMIT PROFILE</Thanks>

  </Main>
  )
};

export default SuccessfulPage;