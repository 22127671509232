import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components'
import Header from '../components/Header'
import GlobalStyles from '../components/styles/Global'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { Container } from '../components/styles/Container.styled';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import LoadingPage from '../components/LoadingPage';


const theme = {
    colors: {
        header: '#E6E6FA',
        body: '#fff',
        footer: '#003333',
    },
    mobile: '768px',
}


function approve_request() {

    const { hash, token } = useParams();
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState('Approved Successfully ! ! !');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const BASE_URI = 'https://j2wofferletter.com/api/v1/';

    const handleClose1 = (event) => {
        setShow(false);
        window.location.href = 'https://j2wofferletter.com/'

    };


    const submitApprove = async () => {
        setLoader(true)
        const res = await fetch(
            `${BASE_URI}approve_request?token=${token}&hashcode=${hash}`
            //  `https://jsonplaceholder.typicode.com/comments?_page=1&_limit=${limit}`
        );

        const data = await res.json();
        setLoader(false)
        if (data.status === 1) {
            // handleShow()
            window.location.href = 'https://j2wofferletter.com/'
            // alert('Mail notification is sent')
        }
        else {
            setMessage('Please try again sometime later!!! ')
            handleShow()
            // alert('Mail notification is not sent, Please try again sometime later!!!')
        }

        // console.log(data.data)

    }

    return (
        <>
            <ThemeProvider theme={theme}>

                <Header />
                <Container>

                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Notification</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {message}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleClose1} >Understood</Button>
                        </Modal.Footer>
                    </Modal>


                    <h2 className="my-4 font-weight-bold .display-2 text-left">Hello,</h2>
                    <h5 className="my-4 font-weight-bold .display-2 text-center">Are you sure want to Approve Requirement</h5>
                    {loader ? (<LoadingPage />) : (<>
                        <div class="row text-center justify-content-around">
                            <Button variant="primary" size="lg" onClick={() => window.location.href = 'https://j2wofferletter.com/'}>Cancel</Button>
                            <Button variant="primary" size="lg" onClick={() => submitApprove()}>Approve</Button>
                        </div>
                    </>)}

                </Container>
            </ThemeProvider>
        </>
    )
}

export default approve_request