import React from 'react';
import styled from 'styled-components';
import j2wlogo_v1 from '../assets/images/j2wlogo_v1.png';
import SocialIcons from './SocialIcons';

const FooterContainer = styled.footer`
  background-color: #003333;
  color: #fff;
  padding: 20px;
  text-align: center;
`;

const CompanyLogo = styled.img`
  max-width: 100px;
  margin-bottom: 10px;
`;

const CompanyName = styled.h5`
  margin: 5px 0;
`;

const Address = styled.h6`
  margin: 5px 0;
`;

const SocialMediaContainer = styled.div`
  margin-top: 20px;
`;

const SocialMediaLink = styled.a`
  margin-right: 10px;
  color: #fff;
  text-decoration: none;

  &:hover {
    color: #ffcc00;
  }
`;

const Footer1 = () => {
  return (
    <FooterContainer>
      <CompanyLogo src={j2wlogo_v1} alt="Company Logo" />
      <CompanyName>JoulestoWatts Business Solutions Pvt Ltd</CompanyName>
      <Address>
        3rd Floor, No. 93/9 K no 731-2389,  
        Novel MSR Park, Varthur Main Road,  
        Munnekolalu, Marathahalli, 
        Bengaluru, Bengaluru Urban, Karnataka, 560037
      </Address>
      <SocialIcons />
      <Address >
        © 2023 joulestowatts. All rights reserved | Made with ❤️ by {'J2W'}
    
      </Address>
    </FooterContainer>
  );
};

export default Footer1;
