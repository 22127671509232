import { FaTwitter, FaFacebook, FaLinkedin } from 'react-icons/fa'
import { FaXTwitter } from "react-icons/fa6";
import { StyledSocialIcons } from './styles/SocialIcons.styled'
import React from 'react';

export default function SocialIcons() {
  return (
    <StyledSocialIcons>
      <li>
        <a href='https://twitter.com/j2w_consulting'>
          <FaXTwitter size={25}/>
        </a>
      </li>
      <li>
        <a href='https://www.facebook.com/joulestowatts/?hc_ref=SEARCH&fref=nf'>
          <FaFacebook size={25} />
        </a>
      </li>
      <li>
        <a href='https://www.linkedin.com/company/joulestowatts'>
          <FaLinkedin size={25} />
        </a>
      </li>
    </StyledSocialIcons>
  )
}
