import React from 'react';
import { ThemeProvider } from 'styled-components';
import Header from '../components/Header';
import GlobalStyles from '../components/styles/Global';
import Footer from '../components/Footer';
import styled from 'styled-components';

import banner from '../assets/images/banner.gif';
import dancing from '../assets/images/dancing.jpg'
import sketching from '../assets/images/sketching.jpg'
import instrumental from '../assets/images/Instrumental.jpg';
import singing from '../assets/images/singing.jpg';
import others from '../assets/images/others.png';


import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';


const theme = {
  colors: {
    header: '#E6E6FA',
    footer: '#003333',
  },
  mobile: '768px',

};



const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
const CardContainer = styled.a`
  width: 300px;
  padding: 80px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('${(props) => props.cardImageUrl}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  color: #fff;
`;

const CardTitle = styled.h2`
  font-size: 1.5rem;
  text-align: center;
  font-family: 'Roboto';
`;

const CardWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center; /* Add this to center the content vertically */
  width: 100%;
  margin-top: 20px;
`;


const CardWrap2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

`;


const FooterContainer = styled.footer`
  margin-top: auto;
`;

const BannerTitle = styled.h1`
  font-size: 2rem;
  text-align: center;
  color: #fff;
`;

// const BannerWrap = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   width: 100;
//   height: 100;
//   justify-content: space-around;
//   margin: 20px;
//   background-image: url('${banner}');
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
// `;



const Terms = styled.p`
margin-top: 20px;
font-size: 0.9rem;
font-weight: bold;
text-align: left;
color: #003333;
padding: 10px

`
const BannerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-self: center;
  height: 550px; /* Set height to a fixed value or adjust as needed */
  justify-content: space-around;
  margin-top: 0px;
  background-image: url('${banner}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 1400px) {
    /* Adjust for tablets and smaller screens */
    height: 400px;
    margin-top: 10px;
    padding: 20px;
    background-size: 100% auto;
  }

  @media (max-width: 768px) {
    /* Adjust for tablets and smaller screens */
    height: 300px;
    margin-top: 10px;
    padding: 20px;
    background-size: 100% auto;
  }

  @media (max-width: 480px) {
    /* Adjust for mobile screens */
    height: 200px;
    margin-top: 5px;
    padding: 10px;
    background-size: 100% auto;
  }
`;



const Card = ({ title, description, link, cardImageUrl }) => {
    return (
        <CardWrap2>
    <CardContainer href={link} target="_blank" rel="noopener noreferrer" cardImageUrl={cardImageUrl}>  
      </CardContainer>
      <CardTitle>{title}</CardTitle>
        </CardWrap2>
   
    );
  };
  

function talent_j2w() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AppContainer>
  
        <Header1 />

    <BannerWrap />

       
      
        <CardWrap>

        <Card
             title="Singing"
             description="This is a simple example card."
             link="https://docs.google.com/forms/d/17GZ3Vs3XeJIdK_1MEItOy6ZoFbJVrd91clA1p3UNd8k/edit"
             cardImageUrl={singing}
            />

          <Card
            title="Dancing"
            description="This is a simple example card."
            link="https://docs.google.com/forms/d/1vQzviPeBBpbKPNN8SJsYz_pbDMZ2DqGzMVSkJVL0VTE/edit"
            cardImageUrl={dancing}
          />  

          <Card
             title="Instrumental Play "
             description="This is a simple example card."
             link="https://docs.google.com/forms/d/1kiMDaOdgLCipXXRluPyWiab7GOL8_KRt4O1vvCVbhsY/edit"
             cardImageUrl={instrumental}
            />


            <Card
             title="Sketching/Painting"
             description="This is a simple example card."
             link="https://docs.google.com/forms/d/1Ew4nzfngIEYE3dV8xEkKwC_GZwQphrCtDVgWCQc2144/edit"
             cardImageUrl={sketching}
            />

            <Card
             title="Others"
             description="This is a simple example card."
             link="https://docs.google.com/forms/d/1GgC_VdHKblAfCbEcMpQg3SxVnNGA2Apx2bERl76oqpY/edit"
             cardImageUrl={others}
            />

         

         
          
          
        
        </CardWrap>
        <Terms>
        Terms and Conditions: <br/> 
        Upload your videos maximum of 3 minutes. <br/>
        Format of the video: Full Name_Category_Emp Code <br/>
        </Terms>
        <FooterContainer>
          <Footer1 />
        </FooterContainer>
      </AppContainer>
    </ThemeProvider>
  );
}

export default talent_j2w;
