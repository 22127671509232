import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components'
import { Container } from '../components/styles/Container.styled';
import Header from '../components/Header';
import GlobalStyles from '../components/styles/Global';
import Footer from '../components/Footer';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { TextField2 } from '../components/TextFieldArea';
import { Rating } from 'react-simple-star-rating'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import LoadingPage from '../components/LoadingPage';
import { useParams } from "react-router-dom";


const theme = {
  colors: {
    header: '#E6E6FA',
    body: '#fff',
    footer: '#003333',
  },
  mobile: '768px',
}


function apprailsForms() {


  const [rating1, setRating1] = useState(1);
  const [rating2, setRating2] = useState(1);
  const [rating3, setRating3] = useState(1);
  const [rating4, setRating4] = useState(1);
  const [rating5, setRating5] = useState(1);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('Form Filled Successfully ! ! !');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { id } = useParams()
  const BASE_URI = 'https://j2wofferletter.com/api/v1/';

  const handleRating1 = async (rate) => {
    setRating1(rate)
  }


  const handleRating2 = async (rate) => {
    setRating2(rate)
  }

  const handleRating3 = async (rate) => {
    setRating3(rate)
  }

  const handleRating4 = async (rate) => {
    setRating4(rate)
  }

  const handleRating5 = async (rate) => {
    setRating5(rate)
  }


  const handleClose1 = (event) => {
    setShow(false);
    window.location.href = 'https://j2wofferletter.com/'

};



  const validate = Yup.object({
    attitude: Yup.string()
      .min(15, 'Must be 15 characters or more')
      .required('Required'),
    functional: Yup.string()
      .min(15, 'Must be 15 characters or more')
      .required('Required'),
    innovation: Yup.string()
      .min(15, 'Must be 15 characters or more')
      .required('Required'),
    leadership: Yup.string()
      .min(15, 'Must be 15 characters or more')
      .required('Required'),
    performance: Yup.string()
      .min(15, 'Must be 15 characters or more')
      .required('Required'),
  })

  const call_api = async (values) => {
    setLoader(true)
    const res = await fetch(
      `${BASE_URI}apprail_form?token=${id}&question_1=${values.attitude}&question_2=${values.functional}&question_3=${values.innovation}&question_4=${values.leadership}&question_5=${values.performance}&rating_1=${rating1}&rating_2=${rating2}&rating_3=${rating3}&rating_4=${rating4}&rating_5=${rating5}`
      //  `https://jsonplaceholder.typicode.com/comments?_page=1&_limit=${limit}`
    );

    const data = await res.json();
    setLoader(false)
    if (data.status === 1) {
      handleShow()
      // alert('Mail notification is sent')
     
    }
    else {
      setMessage('Please try again sometime later!!! ')
      handleShow()
      // alert('Mail notification is not sent, Please try again sometime later!!!')
    }

    // console.log(data.data)

  }


  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose1} > OK </Button>
        </Modal.Footer>
      </Modal>

      <Container>
        {loader ? (<LoadingPage />) : (<>

          <Formik
            enableReinitialize
            initialValues={{
              attitude: '',
              functional: '',
              innovation: '',
              leadership: '',
              performance: '',
            }}
            validationSchema={validate}
            onSubmit={values => {
              // alert(JSON.stringify(values))
              call_api(values)

            }}
          >
            {({ errors, touched, setFieldValue }) => (
              <div>
                <h2 className="my-4 font-weight-bold .display-2 text-center"> Appraisal Process Form</h2>
                <Form>
                  <TextField2

                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}

                    label="Attitude (Willingness to learn and listen, Positive and problem solving attitude)" name="attitude" type="text" />
                  <Rating
                    onClick={handleRating1}
                  />

                  <TextField2
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    label="Functional Expertise (Timelines, Degree of supervision required and speed of response)" name="functional" type="text" />
                  <Rating
                    onClick={handleRating2}
                  />

                  <TextField2
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    label="Innovation and Creativity ( leverages technology , linkedin and other sources for solutioning)" name="innovation" type="text" />
                  <Rating
                    onClick={handleRating3}
                  />

                  <TextField2
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    label="Leadership Skills(Communication Skills, Achievement Orientation, Adaptability, flexibility and proactiveness) " name="leadership" type="text" />
                  <Rating
                    onClick={handleRating4}
                  />

                  <TextField2
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    label="The employee has demonstrated consistent competence in performance. (Fully meets standard performance requirements for this position. Performance is what is expected.)" name="performance" type="text" />
                  <Rating
                    onClick={handleRating5}
                  />

                  {/* <TextField2 label="The employee has demonstrated consistent competence in performance. (Fully meets standard performance requirements for this position. Performance is what is expected.)" name="performance" type="text" /> */}
                  <br></br>
                  <br></br>

                  <button className="btn btn-dark mt-3 " type="submit">Submit</button>
                  <button className="btn btn-danger mt-3 ml-3" type="reset">Reset</button>
                </Form>

              </div>
            )}
          </Formik>

        </>)}


      </Container>

      <Footer />
    </ThemeProvider>
  )
}

export default apprailsForms