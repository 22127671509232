import React from 'react';
import {
  CardWrapper,
  CardImage,
  CardTextWrapper,
  CardTextDate,
  CardTextTitle,
  CardTextBody,
  CardStatWrapper,
  CardStats,
  LinkText
} from './ CardStyles';
import Tilt from "react-parallax-tilt";
import { FaShareAlt, FaPaperPlane, FaLinkedin } from 'react-icons/fa'
import { RWebShare } from "react-web-share";

export const Card = ({ title, date, imgUrl,job_id,skills ,send, location}) => {
  return (
    <Tilt>
      <CardWrapper>
        {/* <CardImage background={imgUrl} /> */}
        <CardTextWrapper>
          <CardTextDate> Job ID : {job_id}</CardTextDate>
          <CardTextTitle>{title}</CardTextTitle>
          <CardTextBody>
           Skills: {skills} <br />
           {"\n"} Locations: {location}
          </CardTextBody>
        </CardTextWrapper>
        <CardStatWrapper>
          {/* <CardStats>
            <div>
              1<sup>m</sup>
            </div>
            <div>read</div>
          </CardStats> */}
            <RWebShare
        data={{
          text: "Job Share ",
          url: `Job Id : ${job_id} \n Title : ${title} \n Skill : ${skills}`,
          title: "J2W Share",
        }}
        onClick={() => console.log("shared successfully!")}
      >
        
         <CardStats>
            <LinkText href="#"><FaShareAlt size={30}/></LinkText>
          </CardStats>
      </RWebShare>
         
          <button class="btn bg-transparent" type="button" onClick={() => send(job_id)} >
          <CardStats>
            <LinkText href="#"><FaPaperPlane size={30}/></LinkText>
          </CardStats>

          </button>


        </CardStatWrapper>
      </CardWrapper>
     </Tilt>
  );
};
