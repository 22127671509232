import React from 'react';
import Lottie from 'lottie-react';
import animation from '../assets/json/35271-try-again.json';
import styled from "styled-components";
import useWindowDimensions from '../utils/useWindowDimensions';


// const Button = styled.button`
//   background-color: #45AAD7;
//   color: white;
//   font-size: 16px;
//   padding: 8px 40px;
//   border-radius: 5px;
//   border-color: #fff;
//   box-shadow: 0in;
//   margin: 8px 0px;
//   cursor: pointer;
//   align-self: center;
// `;

const Main = styled.div`
display:flex ;
flex-direction: column;
color: red;
align-items: center;
margin: 10vh;
`
const Thanks = styled.p`
font-size: 28px;
margin-top: 10px;
font-weight: bold;
color: #E7C640;
font-family: 'Courier New', 'serif', cursive;

@media (max-width: ${({ theme }) => theme.mobile}) {
    margin: 10px 0 10px;
    font-size: 18px;
  
  }

`




const ErrorPage = () => {
  const { width } = useWindowDimensions();
  return (
  <Main>
  {/* <Lottie
  width={width > 768 ? '25%' : '50%'  }
  height={width > 768 ? '25%' : '50%' }
      options={{
        animationData: animation
      }}
    /> */}
         <Lottie
           
           style={{width : width > 768 ? '25%' : '90%', height:width > 768 ? '25%' : '90%', alignSelf:"center"}}
           animationData={animation} />
    <Thanks> OOPS SOMETHING WENT WRONG </Thanks>

  </Main>
)};

export default ErrorPage;