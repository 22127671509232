import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import Header from '../components/Header';
import { Container } from '../components/styles/Container.styled';
import {
  Typography,
  TextField,
  Button,
  Autocomplete,
  Card,
  CardContent,
  Box,
  Alert,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';

const theme = {
  colors: {
    header: '#E6E6FA',
    body: '#fff',
    footer: '#003333',
  },
  mobile: '768px',
};

function AllInvoiceReport() {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [message, setMessage] = useState('');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Fetch clients data on component mount
    axios.get('https://inv.j2wofferletter.com/get_clients')
      .then(response => {
        setClients(response.data);
      })
      .catch(error => {
        console.error('Error fetching clients:', error);
      });
  }, []);

  const handleClientChange = (event, newValue) => {
    setSelectedClient(newValue);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const fetchReport = () => {
    if (!startDate || !endDate) {
      setMessage('Start Date and End Date are required.');
      return;
    }

    setMessage(''); // Clear previous messages if any
    setIsGenerating(true);
    setProgress(0);

    const clientId = selectedClient ? selectedClient.client_id : '';
    const url = `https://inv.j2wofferletter.com/all_invoice?startDate=${startDate}&endDate=${endDate}&clientId=${clientId}`;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        setProgress(percentComplete);
      }
    };

    xhr.onload = () => {
      setIsGenerating(false);
      setProgress(0);

      if (xhr.status === 200) {
        const contentDisposition = xhr.getResponseHeader('Content-Disposition');
        const filename = `AllInvoice_${startDate}_To_${endDate}.xlsx`;

        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
          if (filenameMatch != null && filenameMatch[1]) {
            filename = filenameMatch[1].replace(/['"]/g, '');
          }
        }

        const url = window.URL.createObjectURL(new Blob([xhr.response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        setMessage('Error generating report. Please try again.');
      }
    };

    xhr.onerror = () => {
      setIsGenerating(false);
      setMessage('Error generating report. Please try again.');
    };

    xhr.send();
  };

  const isButtonDisabled = !startDate || !endDate;

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container>
        <Container maxWidth="lg" sx={{ marginTop: 4 }}>
          <Card sx={{ width: 800, margin: '0 auto', padding: 2, marginTop: 5 }}>
            <CardContent>
              <Typography variant="h6" align="center" gutterBottom>
                All Invoice Report
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Autocomplete
                  options={clients}
                  getOptionLabel={(option) => option.client_name}
                  value={selectedClient || null}
                  onChange={handleClientChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Client" variant="outlined" fullWidth />
                  )}
                  sx={{ marginBottom: 2, width: '100%', maxWidth: 500 }}
                />
                <TextField
                  label="Start Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  sx={{ marginBottom: 2, fontSize: '0.875rem', maxWidth: 500 }}
                  value={startDate}
                  onChange={handleStartDateChange}
                />
                <TextField
                  label="End Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  sx={{ marginBottom: 2, fontSize: '0.875rem', maxWidth: 500 }}
                  value={endDate}
                  onChange={handleEndDateChange}
                />
                {message && <Alert severity={message.includes('Error') ? 'error' : 'info'} sx={{ marginBottom: 2 }}>{message}</Alert>}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={fetchReport}
                  sx={{ marginTop: 2, width: '100%', fontSize: '0.875rem' }}
                  disabled={isButtonDisabled || isGenerating}
                >
                  {isGenerating ? <CircularProgress size={24} /> : 'Generate Report'}
                  {isGenerating && ` ${progress}%`}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Container>
    </ThemeProvider>
  );
}

export default AllInvoiceReport;
