import React from 'react';
import styled from "styled-components";
import { ThemeProvider } from 'styled-components'
import { Container } from '../components/styles/Container.styled';
import Header from '../components/Header';
import GlobalStyles from '../components/styles/Global'
import Footer from '../components/Footer';
import Table from 'react-bootstrap/Table';
import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import LoadingPage from '../components/LoadingPage';


const theme = {
  colors: {
    header: '#E6E6FA',
    body: '#fff',
    footer: '#003333',
  },
  mobile: '768px',
}

export const TextTitle = styled.h2`
  margin-top: 0px;
  font-size: 2rem;
  box-sizing: border-box;
  min-width: 0px;
  font-size: 20px;
  line-height: 1.2;
  margin: 0px;
  background: #083333;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
`;


export const CardTextTitle = styled.h2`
  margin-top: 0px;
  font-size: 2rem;
  box-sizing: border-box;
  min-width: 0px;
  font-size: 22px;
  line-height: 1.2;
  margin: 0px;
  background: #083333;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  padding-bottom: 20px;
`;


const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: #1f2229; */
  overflow: hidden;

`;

function apprailList() {
  const [items, setItems] = useState([]);
  const [datas, setDatas] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const history = useHistory();
  const { id } = useParams()

  const BASE_URI = 'https://j2wofferletter.com/api/v1/';

  let limit = 9;

  useEffect(() => {
    const getComments = async () => {
      const res = await fetch(
        `${BASE_URI}apprail_list?page=1&token=${id}`
        //  `https://jsonplaceholder.typicode.com/comments?_page=1&_limit=${limit}`
      );


      const data = await res.json();

      //  console.log(data.data)
      const total = res.headers.get("x-total-count");
      setpageCount(data.meta.total_pages);
      setDatas(data)
      setLoader(false)
      setItems(data.data);
    };

    getComments();
  }, [limit]);


  const fetchComments = async (currentPage) => {
    setLoader(true)
    const res = await fetch(
      `${BASE_URI}apprail_list?page=${currentPage}&token=${id}`
      // `https://jsonplaceholder.typicode.com/comments?_page=${currentPage}&_limit=${limit}`
    );
    const data = await res.json();
    setLoader(false)
    return data.data;
  };



  const handlePageClick = async (data) => {
    console.log(data.selected);

    let currentPage = data.selected + 1;

    const commentsFormServer = await fetchComments(currentPage);

    setItems(commentsFormServer);
  }


  const openjobs = (user_id) => {
    history.push(`/appraisalview/${user_id}/${id}`);
  }


  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header />
      <Container>
        <TextTitle>Appraisal Recruiters List :</TextTitle>
        <CardContainer>

          {/* <CardTextTitle>Total In-Progress Employees: {datas.count}</CardTextTitle> */}
        </CardContainer>
        {loader ? <LoadingPage /> : (<>
          <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Full Name</th>
              <th>Emp ID</th>
              <th>Email</th>
              <th>Phone No</th>
              <th>Apprail Priods</th>
              <th>Action</th>
            </tr>
          </thead>
       
            <tbody>
            {
              items.map((item) => {
                return (
                  <tr>
                    <td>{item.candidate_id}</td>
                    <td>{item.full_name}</td>
                    <td>{item.emp_id}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>{item.peroid}</td>
                    <td><button type="button" class="btn btn-primary" onClick={() => openjobs(item.token)} > Preview </button>
                    </td>
                  </tr>
                );
              })
            } 
          </tbody>
          
         
        
        </Table>
        </>)}
      
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </Container>
      <Footer />
    </ThemeProvider>

  )
}
export default apprailList;