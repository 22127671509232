import { StyledHeader, Nav, Logo } from './styles/Header.styled'
import { Container } from './styles/Container.styled'
import { Button } from './styles/Button.styled'
import React from 'react';
import j2wlogo_v1 from '../assets/images/j2wlogo_v1.png';
import { constants } from '../config/constants';


const redirectLink = () =>{
  window.location.href = `${constants.RedirectURL}`; 
     return null;
}

export default function Header() {
  return (
    <StyledHeader>
      <Container>
        <Nav>
          <Logo src={j2wlogo_v1} alt='' />
        
          {/* <Button onClick={redirectLink} >Login</Button> */}
        </Nav>

        {/* <Flex>
          <div>
            <h1>Build The Community Your Fans Will Love</h1>

            <p>
              Huddle re-imagines the way we build communities. You have a voice,
              but so does your audience. Create connections with your users as
              you engage in genuine discussion.
            </p>

            <Button bg='#ff0099' color='#fff'>
              Get Started For Free
            </Button>
          </div>

          <Image src='./images/illustration-mockups.svg' alt='' />
        </Flex> */}
      </Container>
    </StyledHeader>
  )
}
