import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import candidateUpdateScreen from './screens/candidateUpdateScreen';
import NotFound from './screens/NotFound';
import WelcomePage from './screens/WelcomePage';
import updateForm from './components/updateForm';
import jobScreen from "./screens/jobScreen";
import inprogressScreen from "./screens/inprogressScreen";
import apprailsForms from "./screens/apprailsForms";
import apprailsView from "./screens/apprailsView";
import apprailList from './screens/apprailList';
import softwareChangeForm from "./screens/softwareChangeForm";
import approve_request from "./screens/approve_request";
import authControl from "./screens/authControl";
import talent_j2w from "./screens/talent_j2w";
import approvalPo from "./screens/approvalPo";
import lwdApproval from "./screens/lwdApproval";
import all_InvoiceReport from "./screens/all_InvoiceReport";

export default function App() {
  return (
    <Router>
   
      
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
    
          <Route path="/users/:id" component={candidateUpdateScreen}>
          </Route>
          <Route path="/hrbp/:id/:token" component={jobScreen} />
          <Route path="/inprogress/:id" component={inprogressScreen} />
          {/* <Route exact path="/" component={WelcomePage} /> */}
          {/* <Route exact path="/update" component={updateForm} /> */}
          <Route exact path="/appraisal/:id" component={apprailsForms} />
          <Route exact path="/appraisalview/:id/:token" component={apprailsView} />
          <Route exact path="/appraisallist/:id/" component={apprailList} />
          <Route exact path="/softwareChangeForm/:id/" component={softwareChangeForm} />
          <Route exact path="/approve_request/:hash/:token" component={approve_request} />

          <Route exact path="/approve_p_o/:id/:token" component={approvalPo} />

          <Route exact path="/lwd_approval/:id/:token/:bh_token" component={lwdApproval} />
          <Route exact path="/lwd_approval/:id/:token" component={lwdApproval} />
          {/* <Route exact path="/auth" component={authControl} /> */}

          <Route exact path="/talent" component={talent_j2w} />

          <Route exact path="/invoice_reports" component={all_InvoiceReport} />

          <Route component={NotFound} />
        </Switch>
    
    </Router>
  );
}


