import React, { Component } from 'react'

import { FixedSizeList as List } from "react-window";
import Select from "react-select";
import { ErrorMessage, useField } from 'formik';
import 'bootstrap/dist/css/bootstrap.min.css';

const options = [];
for (let i = 0; i < 10000; i = i + 1) {
  options.push({ value: i, label: `Option ${i}` });
}


class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * 35;

    return (
      <List
        height={300}
        itemCount={children.length}
        itemSize={35}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}


export default class Lists extends Component {


  render() {  
    return (
      <div className="mb-2">
          <label className="text-decoration-line-through" style={{fontWeight:'bold', fontSize:12 , color:'#434175'}} >Skill</label>
      <Select isMulti onChange={this.props.onChange} components={{ MenuList }} options={this.props.options} />
      <ErrorMessage component="div" name={'skill'} className="error" />
      </div>
    )
  }
}
