import React from 'react'
import { useEffect, useState } from "react";
import { ThemeProvider } from 'styled-components'
import Header from '../components/Header';
import GlobalStyles from '../components/styles/Global';
import styled from "styled-components";
import { Container } from '../components/styles/Container.styled';
import Table from 'react-bootstrap/Table';
import { useParams } from "react-router-dom";
import Lottie from 'lottie-react';
import animation from '../assets/json/35271-try-again.json'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Rating } from 'react-simple-star-rating'


const theme = {
  colors: {
    header: '#E6E6FA',
    body: '#fff',
    footer: '#003333',
  },
  mobile: '768px',
}

export const TextTitle = styled.h2`
  margin-top: 0px;
  font-size: 2rem;
  box-sizing: border-box;
  min-width: 0px;
  line-height: 1.2;
  margin: 0px;
  background: #083333;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
`;


export const TextTitleSubhead = styled.h4`
  margin-top: 0px;
  font-size: 1rem;
  box-sizing: border-box;
  min-width: 0px;
  line-height: 1.2;
  margin: 0px;
  background: #083333;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
`;

export const MainContainer = styled.div`
    display: flex;
   align-items: center;
   justify-content: space-around;
   margin: 100px;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
  
  }
`;


export const Column = styled.div`
    display: flex;
    padding: 25px;
    flex-direction: column;
`;


export const TableStyle = styled.div`
    display: flex;
    padding-left: 5%;
    padding-right: 5%;
    align-self: center;
`;



function apprailsView() {

  const [items, setItems] = useState([]);
  const [employee, setEmp] = useState([]);
  const [apprails, setApprails] = useState([]);

  const [error, setError] = useState(false);
 
  const { id, token } = useParams();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('Approved Successfully ! ! !');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [comments, setComments] = useState([]);
  const [rating, setRating] = useState(0);

  const handleClose1 = (event) => {
    setShow(false);
    window.location.href='https://j2wofferletter.com/'

  };

  const BASE_URI = 'https://j2wofferletter.com/api/v1/';

  let limit = 9;

  useEffect(() => {
    const getComments = async () => {
      const res = await fetch(
        `${BASE_URI}apprail_review?&token=${id}&auth=${token}`
        //  `https://jsonplaceholder.typicode.com/comments?_page=1&_limit=${limit}`
      );


      const data = await res.json();

      if (data.status !== 0) {
        setEmp(data.employee_details)
        setItems(data.data)
        setApprails(data.apprail_details)
        //  console.log(data.data)
      } else {
        setError(true)
      }
    };

    getComments();
  }, [limit]);


  const submitApprove = async () => {
    if (comments === '') {
      alert('Please add comments ')
    }
    else if(rating === 0){
      alert('Please give Ratings')
    }
    else {
      const res = await fetch(
        `${BASE_URI}apprail_approval?token=${token}&user=${id}&ratings=${rating}&comments=${comments}`
        //  `https://jsonplaceholder.typicode.com/comments?_page=1&_limit=${limit}`
      );

      const data = await res.json();

      if (data.status === 1) {
        handleShow()
        // alert('Mail notification is sent')
      }
      else {
        setMessage('Please try again sometime later!!! ')
        handleShow()
        // alert('Mail notification is not sent, Please try again sometime later!!!')
      }

      // console.log(data.data)
    }
  }

  const handleRating = async (rate) => {
    setRating(rate)
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header />
      {error ? (<>
        <Container>
          <TextTitle> Appraisal  Form - Recruiters </TextTitle>

          <br></br>
          <TextTitle> KPI is not set</TextTitle>
          <MainContainer>
            <Lottie
              style={{ width: '50%', height: '50%', alignSelf: 'center' }}
              animationData={animation} />
          </MainContainer>

        </Container>

      </>) : (<>


        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {message}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose1} >OK</Button>
          </Modal.Footer>
        </Modal>

        <Container>
          <TextTitle> Appraisal  Form - Recruiters </TextTitle>


          <Row>
            <Row> Employee Name: {employee.full_name} </Row>
            <Row> Employee Id: {employee.employee_id} </Row>
          </Row>

          <Row>
            <Row> Designation: {employee.designation}</Row>
            <Row> Location: {employee.location} </Row>
          </Row>

          <Row>
            {/* <Row> Department/ Team: Tech</Row> */}
            <Row> Review Period From: {employee.apprails_start_date} To {employee.apprails_end_date} </Row>
          </Row>
          <Row>

            {/* <Row> Appraiser: Tech</Row> */}
            <Row>  </Row>
          </Row>
        </Container>
        <TextTitle> Section A: Potential Assessment </TextTitle>
        <TableStyle>
          <Table striped bordered hover responsive="sm">

            <thead>
              <tr>

                <th>Current KRA</th>
                <th>Measure (s) of Completion ( Key activities which needs to be done to accomplish the KRA )</th>
                <th>Time/ Frequency</th>
                <th>Relative Weightage (in % age)</th>
                <th>Percentage of Performance Achievement</th>
              </tr>
            </thead>
            <tbody>

              {
                items.map((item) => {
                  return (
                    <tr>
                      <th>{item.title}</th>
                      <td>{item.achieve} / {item.target}</td>
                      <td>{item.frequency}</td>
                      <td>5%</td>
                      <td bgcolor={Math.round(item.achieve / item.target * 100)>=90 ? 'green': Math.round(item.achieve / item.target * 100)>=70 ? 'orange' : 'red'} style={{color:'white' , textAlign:'center', fontWeight:"bold"}} >{Math.round(item.achieve / item.target * 100)}%</td>
                    </tr>
                  );
                })
              }

            </tbody>
          </Table>
        </TableStyle>
        <TextTitle> Section B: Potential Assessment </TextTitle>

        <TableStyle>
          <Table striped bordered hover responsive="sm">

            <thead>
              <tr>

                <th>Competency</th>
                <th>EXAMPLES OF DEMONSTRATED BEHAVIOUR</th>
                <th>Time/ Frequency</th>
                <th>Sef Rating</th>
              </tr>
            </thead>
            <tbody>
              <tr>

                <th>Attitude (Willingness to learn and listen, Positive and problem solving attitude) </th>
                <td>{apprails.question_1}</td>
                <td>Yearly</td>
                <td>{apprails.rating_1}</td>
              </tr>
              <tr>

                <th>Functional Expertise (Timelines, Degree of supervision required and speed of response)</th>
                <td>{apprails.question_2}</td>
                <td>Yearly</td>
                <td>{apprails.rating_2}</td>
              </tr>
              <tr>

                <th>Innovation and Creativity ( leverages technology , linkedin and other sources for solutioning)</th>
                <td>{apprails.question_3}</td>
                <td>Yearly</td>
                <td>{apprails.rating_3}</td>

              </tr>

              <tr>

                <th>Leadership Skills (Communication Skills, Achievement Orientation, Adaptability, flexibility and proactiveness)</th>
                <td>{apprails.question_4}</td>
                <td>Yearly</td>
                <td>{apprails.rating_4}</td>

              </tr>

              <tr>

                <th> The employee has demonstrated consistent competence in performance. (Fully meets standard performance requirements for this position. Performance is what is expected.) </th>
                <td>{apprails.question_5}</td>
                <td>Yearly</td>
                <td>{apprails.rating_5}</td>

              </tr>

            </tbody>
          </Table>
        </TableStyle>
        <TextTitle> Section C: Approvals </TextTitle>
              <MainContainer>
        <TableStyle>
          <Table striped bordered hover responsive="sm">

            <thead>
              <tr>

                <th>Approval Holders</th>
                <th>Comments</th>
                <th>Ratings</th>
                <th>Approval</th>
              </tr>
            </thead>
            <tbody>
              <tr>

                <th>Business Head</th>
                <td>{apprails.bh_comments}</td>
                <td>{apprails.bh_rating}</td>
                <td>{apprails.bh_approval? "Approved" : "Approval Pending"} </td>
              </tr>
              <tr>

                <th>Manager</th>
                <td>{apprails.manager_comments}</td>
                <td>{apprails.manager_rating}</td>
                <td>{apprails.manager_approval? "Approved" : "Approval Pending"} </td>
              </tr>
              <tr>

                <th>Lead</th>
                <td>{apprails.lead_comments}</td>
                <td>{apprails.lead_rating}</td>
                <td>{apprails.lead_approval? "Approved" : "Approval Pending"} </td>

              </tr>

              <tr>

                <th>HR</th>
                <td>{apprails.hr_comments}</td>
                <td>{apprails.hr_rating}</td>
                <td>{apprails.hr_approval? "Approved" : "Approval Pending"} </td>

              </tr>


            </tbody>
          </Table>
        </TableStyle>
        </MainContainer>
        <Container>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label><TextTitleSubhead> Comments </TextTitleSubhead></Form.Label>
            <Form.Control as="textarea" rows={3} onChange={(event) => { setComments(event.target.value) }} />
          </Form.Group>
          <Form.Label><TextTitleSubhead> Ratings : </TextTitleSubhead></Form.Label>
          <Rating
            onClick={handleRating}
          />
          <MainContainer>

            <Button variant="primary" size="lg" onClick={() => submitApprove()}>Approve</Button>

          </MainContainer>
        </Container>



      </>)}


    </ThemeProvider>
  )
}

export default apprailsView