import React from 'react';
// import { Link } from 'react-router-dom';
// import Lottie from 'lottie-react';
// import animation from '../assets/json/welcome_web.json'
import styled from "styled-components";
import { ThemeProvider } from 'styled-components'
import { Container } from '../components/styles/Container.styled';
import Header from '../components/Header';
import GlobalStyles from '../components/styles/Global';
import Footer from '../components/Footer';
import { Card } from '../components/Card/Card';
import { FilterTextbox } from '../components/search-bar';
import {  Row } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import LoadingPage from '../components/LoadingPage';

const theme = {
  colors: {
    header: '#E6E6FA',
    body: '#fff',
    footer: '#003333',
  },
  mobile: '768px',
}

const CardContainer = styled.div`
  width: 100vw;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #1f2229; */
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
    overflow: hidden;
  }
`;



export const TextTitle = styled.h2`
  margin-top: 0px;
  font-size: 2rem;
  box-sizing: border-box;
  min-width: 0px;
  line-height: 1.2;
  margin: 0px;
  background: #083333;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
`;


export const CardTextTitle = styled.h2`
  margin-top: 0px;
  font-size: 2rem;
  box-sizing: border-box;
  min-width: 0px;
  font-size: 22px;
  line-height: 1.2;
  margin: 0px;
  background: #083333;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

const SearchContainer = styled.div`
 width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  /* background: #1f2229; */
  
  
`;

// const Separator = styled.span`
//   margin-left: 10px;
//   margin-right: 10px;
// `;


const data = {
  content: {
    body: [
      {
        _uid: "BUY6Drn9e1",
        component: "foo",
        headline: "Foo"
      },
      {
        _uid: "gJZoSLkfZV",
        component: "qar",
        title: "Bar"
      },
      {
        _uid: "X1JAfdsZxy",
        component: "foo",
        headline: "Another headline"
      },
      {
        _uid: "X1JAfdsZxy",
        component: "foo",
        headline: "Another headline"
      },
      {
        _uid: "X1JAfdsZxy",
        component: "foo",
        headline: "Another headline"
      },
      {
        _uid: "X1JAfdsZxy",
        component: "foo",
        headline: "Another headline"
      }
    ]
  }
};


function jobScreen() {
  const [items, setItems] = useState([]);
  const [datas, setDatas] = useState([]);
  const [loader, setLoader] = useState(true);
  const [candidate, setCandidate] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [search, setSearch] = useState('');
  const { id,token } = useParams();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState(' Mail sent Successfully ! ! !')
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  let limit = 9;

  const BASE_URI = 'https://j2wofferletter.com/api/v1/';

  useEffect(() => {

    const getComments = async () => {
    
      const res = await fetch(
        `${BASE_URI}job_api?page=1&user_id=${id}`
        //  `https://jsonplaceholder.typicode.com/comments?_page=1&_limit=${limit}`
      );


      const data = await res.json();

      if(data.status ===1)
      {
            console.log(data.candidate.candidate_name)
            setLoader(false)
            const total = res.headers.get("x-total-count");
            setpageCount(data.meta.total_pages);
            setDatas(data);
            setCandidate(data.candidate)
            console.log(datas)
            setItems(data.data);
      }
      else{
        setLoader(true)
      }
            
    };

    getComments();
  }, [limit]);

  const fetchComments = async (currentPage) => {

    setLoader(true)
    // alert(search)
    const res = await fetch(
      `${BASE_URI}job_api?page=${currentPage}&user_id=${id}&search=${search}`
      // `https://jsonplaceholder.typicode.com/comments?_page=${currentPage}&_limit=${limit}`
    );
    const data = await res.json();
    setLoader(false)
    return data.data;
  };



  const handlePageClick = async (data) => {
    console.log(data.selected);
  
    let currentPage = data.selected + 1;

    const commentsFormServer = await fetchComments(currentPage);
    
    setItems(commentsFormServer);
  }


  const searchText = async (text) => {
    setLoader(true)
    const res = await fetch(
      `${BASE_URI}job_api?page=1&user_id=${id}&search=${text}`
      // `https://jsonplaceholder.typicode.com/comments?_page=${currentPage}&_limit=${limit}`
    );
    const data = await res.json();
    setpageCount(data.meta.total_pages);
    setDatas(data);
    setCandidate(data.candidate)
    setLoader(false)
    // console.log(datas)
    setItems(data.data);
    return data.data;
  };



  const onSubmit = async (text) => {

    setSearch(text)
   
    const commentsFormServer = await searchText(search);

    setItems(commentsFormServer);
  }

  const send = async (job_id) => {
    setLoader(true)
    const res = await fetch(
      `${BASE_URI}send_exit_profile?job_id=${job_id}&user_id=${id}&token=${token}`
      // `https://jsonplaceholder.typicode.com/comments?_page=${currentPage}&_limit=${limit}`
    );
    const data = await res.json();
    setLoader(false)
    if (data.status == 1) {
      handleShow()
      // alert('Mail notification is sent')
    }
    else {
      setMessage('Mail notification is not sent, Please try again sometime later!!! ')
      handleShow()
      // alert('Mail notification is not sent, Please try again sometime later!!!')
    }

  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Mail Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose} >Understood</Button>
        </Modal.Footer>
      </Modal>

      <SearchContainer>
        <FilterTextbox onSubmit={onSubmit} />
      </SearchContainer>
      <Container>

        <TextTitle>Recommended Jobs :</TextTitle>
        <CardTextTitle>Candidate Name:  {candidate.candidate_name}</CardTextTitle>
        <CardTextTitle>LWD:  {candidate.last_work_day}</CardTextTitle>
        <CardTextTitle>Key Skills: {candidate.candidate_skill}</CardTextTitle>
        {loader ? <LoadingPage /> :
          (<Row xs={3}>

            {items.map((item) => {
              return (
                <CardContainer>
                  <Card title={item.title} job_id={item.id} skills={item.skills} send={send} location={item.location} />
                </CardContainer>

              );
            })}

          </Row>)}

       

        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </Container>




      <Footer />
    </ThemeProvider>
  );
}

export default jobScreen;