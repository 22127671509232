import React from 'react';
// import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import animation from '../assets/json/loader.json';
import styled from "styled-components";
import useWindowDimensions from '../utils/useWindowDimensions';
import Loader from "react-js-loader";

// const Button = styled.button`
//   background-color: #45AAD7;
//   color: white;
//   font-size: 16px;
//   padding: 8px 40px;
//   border-radius: 5px;
//   border-color: #fff;
//   box-shadow: 0in;
//   margin: 8px 0px;
//   cursor: pointer;
//   align-self: center;
 
// `;

const Main = styled.div`
display:flex ;
flex-direction: column;
color: red;
align-items: center;
margin: 10vh;
`
const Thanks = styled.p`
font-size: 28px;
margin-top: 10px;
font-weight: bold;
color: #268581;
font-family: 'Courier New', 'serif', cursive;

@media (max-width: ${({ theme }) => theme.mobile}) {
    margin: 40px 0 30px;
  
  }

`






const LoadingPage = () => {
  const { width } = useWindowDimensions();
  return (
  <Main>
  {/* <Lottie
  width={width > 768 ? '20%' : '50%'  }
  height={width > 768 ? '20%' : '50%' }
      options={{
        animationData: animation
      }}
    /> */}
     <div className={"item"}>
                <Loader type="spinner-circle" bgColor={"#9EC8C7"}   size={100} />
            </div>
    <Thanks> PLEASE WAIT !!! </Thanks>

  </Main>
)};;

export default LoadingPage;