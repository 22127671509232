import React from 'react'
import { useEffect, useState } from "react";

function authControl() {

    const BASE_URI = 'http://localhost:3000/api/v1/';

    useEffect(() => {
        const getComments = async () => {
          const res = await fetch(
            `${BASE_URI}session_data`
            //  `https://jsonplaceholder.typicode.com/comments?_page=1&_limit=${limit}`
          );
    
    
          const data = await res.json();
    
           console.log(data)
        //   const total = res.headers.get("x-total-count");
        //   setpageCount(data.meta.total_pages);
        //   setDatas(data)
        //   setLoader(false)
        //   setItems(data.data);
        };
    
        getComments();
      }, []);

  return (
    <div>authControl</div>
  )
}

export default authControl