import SocialIcons from './SocialIcons'
import { Container } from './styles/Container.styled'
import { Flex } from './styles/Flex.styled'
import { StyledFooter } from './styles/Footer.styled'
import React from 'react';
import j2wlogo_v1 from '../assets/images/j2wlogo_v1.png';

export default function Footer() {
  return (
    <StyledFooter>
      <Container>
        <img src={j2wlogo_v1} alt='' />

        <Flex>
          <ul>
            <li>
              JoulestoWatts Business Solutions
              3rd Floor, No. 93/9 K no 731-2389, 
              Novel MSR Park, Varthur Main Road, 
              Munnekolalu, Marathahalli,
              Bengaluru,Bengaluru Urban,Karnataka, 560037
            </li>
           
            <li>info@joulestowatts.com</li>
          </ul>
          {/* <ul>
            <li>About Us</li>
            <li>What We Do</li>
            <li>FAQ</li>
          </ul> */}

          <ul>
            {/* <li>Career</li>
            <li>Blog</li>
            <li>Contact Us</li> */}
          </ul>

          <SocialIcons />
        </Flex>

        <p>&copy; 2023 joulestowatts. All rights reserved</p>
      </Container>
    </StyledFooter>
  )
}
